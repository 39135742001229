/* eslint camelcase: off */
import { useNuxtApp } from 'nuxt/app';
import { defineStore } from 'pinia';
import { useUserStore } from '~/stores/User';

/**
 * @todo This Pinia store will eventually be used for Alert Areas notifications. Currently Alert
 * Areas are managed in the User Pinia store, but should be moved here for consistency.
 */
export const useUserNotificationsStore = defineStore('userNotifications', {
  state: () => ({
    alertAreas: null,
    dailyReads: null,
    locations: null,
    newsletters: null,
  }),

  actions: {
    async fetchAlertAreas() {
      const { $api } = useNuxtApp();
      const userStore = useUserStore();
      const { cache_key } = userStore.user || {};
      const { units } = userStore.preferences;

      const { alert_areas, locations } = await $api.get('/user/notifications/alert-areas', { cache_key, units });
      this.saveAlertAreas({ alert_areas, locations });
    },

    async fetchAlertAreasHistory(payload = {}) {
      const { limit = 20, skip = 0 } = payload;
      const { $api } = useNuxtApp();
      const userStore = useUserStore();
      const { cache_key } = userStore.user || {};
      const { units } = userStore.preferences;

      return $api.get('/user/notifications/alert-areas/history', { cache_key, limit, skip, units });
    },

    async fetchDailyReads() {
      const { $api } = useNuxtApp();
      const userStore = useUserStore();
      const { cache_key } = userStore.user || {};

      const { daily_reads } = await $api.get('/user/notifications/daily-reads', { cache_key });
      this.saveDailyReads(daily_reads);
    },

    async fetchNewsletters() {
      const { $api } = useNuxtApp();
      const userStore = useUserStore();
      const { cache_key } = userStore.user || {};

      const response = await $api.get('/user/notifications/newsletters', { cache_key });
      this.saveNewsletters(response);
    },

    async optInToReceiveNewsletters() {
      const { $api } = useNuxtApp();
      const response = await $api.post('/user/notifications/newsletters/external-opt-in');
      this.saveNewsletters(response);
    },

    async removeAlertArea(alertAreaId) {
      const { $api } = useNuxtApp();
      const { alert_areas, locations } = await $api.delete(`/user/notifications/alert-areas/${alertAreaId}`);
      this.saveAlertAreas({ alert_areas, locations });
    },

    saveAlertAreas({ alert_areas, locations }) {
      this.alertAreas = alert_areas;
      this.locations = locations;
    },

    saveDailyReads(dailyReads) {
      this.dailyReads = dailyReads;
    },

    saveNewsletters(newsletters) {
      this.newsletters = newsletters;
    },

    async subscribeToDailyRead(dailyReadId) {
      const { $api } = useNuxtApp();
      const { daily_reads } = await $api.put(`/user/notifications/daily-reads/${dailyReadId}`);
      this.saveDailyReads(daily_reads);
    },

    async subscribeToNewsletterSegment(segmentId) {
      const { $api } = useNuxtApp();
      const response = await $api.post(`/user/notifications/newsletters/subscribe/${segmentId}`);
      this.saveNewsletters(response);
    },

    async unsubscribeToDailyRead(dailyReadId) {
      const { $api } = useNuxtApp();
      const { daily_reads } = await $api.delete(`/user/notifications/daily-reads/${dailyReadId}`);
      this.saveDailyReads(daily_reads);
    },

    async unsubscribeToNewsletter(newsletterId) {
      const { $api } = useNuxtApp();
      const path = `/user/notifications/newsletters/unsubscribe${newsletterId ? `/${newsletterId}` : ''}`;
      const response = await $api.post(path);
      this.saveNewsletters(response);
    },

    async updateAlertArea(alert_area) {
      const { $api } = useNuxtApp();
      const userStore = useUserStore();
      const { units } = userStore.preferences;
      const { alert_areas, locations } = await $api.put('/user/notifications/alert-areas', { units }, { alert_area });
      this.saveAlertAreas({ alert_areas, locations });
    },

    async updateNotificationsPreferences({ marketing, notification }) {
      const { $api } = useNuxtApp();
      await $api.post('/user/notifications/preferences', undefined, { marketing, notification });
    },
  },

  getters: {
    getAlertAreaByLocationId: (state) => (locationId) => {
      if (state.alertAreas) {
        return state.alertAreas.find((alertArea) => alertArea.location_id === locationId);
      }

      return null;
    },

    isSubscribedToDailyRead: (state) => ({ dailyReadId }) => {
      const dailyReadIds = state?.dailyReads?.flatMap((item) => item.id) || [];

      if (dailyReadIds) {
        return dailyReadIds.includes(dailyReadId);
      }

      return false;
    },
  },
});
